<template>
  <!-- {{ loading }}
  <div v-if="loading">

  </div> -->
    <div  class="home">
      <!-- // Get A Quote  -->
      <SliderComponents v-if="!loadingHome"></SliderComponents>
      <SearchComonent v-if="!loadingHome"></SearchComonent>
      <!-- <TrackView v-if="Object.keys(shipment).length !== 0"></TrackView> -->

      <AboutComponent v-if="!loadingHome"></AboutComponent>
      <ServicesComponent v-if="!loadingHome"></ServicesComponent>
      <ArticalComponent v-if="!loadingHome"></ArticalComponent>
        
   
  </div>
    
</template>

  <script setup>
  import SliderComponents from "./components/SliderComponents.vue"
  import SearchComonent from "./components/SearchComonent.vue"
  // import TrackView from "@/views/track/TrackView.vue"

  import AboutComponent from "./components/AboutComponent.vue"
  import ServicesComponent from "./components/ServicesComponent.vue"
  import ArticalComponent from "./components/ArticalComponent.vue"
  import { onMounted } from 'vue'
  // store
  import { useHome } from "./store"

  const store = useHome()
  
  import { storeToRefs } from "pinia"
 
  const { loadingHome } = storeToRefs(store)

  // import { useTrack  } from "@/views/track/store"
  // import { storeToRefs } from "pinia"

  // const storeTrack = useTrack()

  // const { shipment } = storeToRefs(storeTrack)

  onMounted(async() => {
    await store.fetchInfo()
    await store.fetchContactInfo() 
   })
  </script>
  
  
  