<template>
    <!--============= About Us (en) =============-->
    <div v-if="lang === 'en'" class="nile-about-section text-left" id="About">

        <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title-right text-main-color clearfix">
                    <div class="icon"><img src="../../../assets/icons/title-icon-1.png" alt=""></div>
                    <h2 class="title-text">About Our Company</h2>
                </div>
                <div class="about-text margin-tb-25px">{{ aboutCompany }}</div> <!-- <a href="#" class="nile-bottom sm">Read More</a> -->
            </div>
            <div class="col-lg-6 d-flex justify-content-end">
                        <img  class="aboutImage" src="../../../assets/about.jpg" alt="">
                </div>

            </div>
        </div>
    </div>
    <!--============= //About Us =============-->
    <!--============= About Us (ar) =============-->
    <div v-else class="nile-about-section text-left" id="About">
        <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title-right-ar text-main-color clearfix">
                    <div class="icon"><img src="../../../assets/icons/title-icon-1.png" alt=""></div>
                    <h2 class="title-text text-right">  <span v-if="lang==='ar'">عن شركتنا</span> <span v-if="lang==='kr'"> دەربارەی کۆمپانیاکەمان </span> </h2>
                </div>
                <div class="about-text margin-tb-25px text-right">{{ aboutCompany }}</div> <!-- <a href="#" class="nile-bottom sm">Read More</a> -->
            </div>
            <div class="col-lg-6">
                <img class="aboutImage" src="../../../assets/about.jpg" alt="">
            </div>
           


            </div>
        </div>
    </div>
    <!--============= //About Us =============-->
</template>

<script setup>
import {onMounted , ref } from 'vue'
 import { useHome } from "../../home/store"
  import { storeToRefs } from "pinia"

  const store = useHome()

  const {  contactInfoConst } = storeToRefs(store)

  
  const aboutCompany = ref('')
  const lang = localStorage.getItem('lang') 
  onMounted( async() => {
    
    await store.fetchContactInfo() 
    aboutCompany.value =  contactInfoConst.value.filter(item => item.slug === "about-the-company")[0].value
   })
</script>
<style>
.section-title-right-ar .icon {
    float: right;
    margin-left: 20px;
    margin-top: -10px;
}
.aboutImage {
    width: 400px;
    height: 350px;
    object-fit: cover;
}
</style>

