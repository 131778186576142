<template>
    <!--============= Artical =============-->
    <div v-if="lang==='en'" class="section padding-tb-100px section-ba-3  text-left">
        <div class="container">
            <!-- Title -->
            <div class="section-title margin-bottom-40px">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div class="icon text-main-color"><i class="fa fa-bookmark-o"></i></div>
                        <div class="h2">Our Blog</div>
                        <!-- <div class="des">{{ ArticalText }} </div> -->

                    </div>
                </div>
            </div>
            <!-- // End Title -->
            <div class="row">
                <div v-if="loading"></div>

                <div v-else class="col-lg-4 col-md-6 sm-mb-35px my-2" v-for="item in latest_articles" :key="item.id">
                    <div class="blog-item">
                        <div class="img">
                            <a href="#"><img v-if="item.main_image" class="coverImage" :src="item.main_image.preview" alt=""></a>

                            <a href="#" class="date">
								<span class="day">{{getDay(item.created_at)}}</span>
								<span class="month">{{getMonth(item.created_at)}}</span>
							</a>
                        </div>
                        
                        <router-link  :to="{ name: 'singleBlog', params: { id: `${item.id}` } }" class="title titleSize">{{item.title}}</router-link>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!--============= //Artical =============-->
    <!--============= Artical =============-->
   
    <div v-else class="section padding-tb-100px section-ba-3  text-left">
        <div class="container">
            <!-- Title -->
            <div class="section-title margin-bottom-40px">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div class="icon text-main-color"><i class="fa fa-bookmark-o"></i></div>
                        <div class="h2 directionLtr"> <span v-if="lang === 'ar'">مقالاتنا</span>  <span v-if="lang === 'kr'">بابەتەکانمان</span></div>
                        <!-- <div class="des">{{ ArticalText }} </div> -->
                    </div>
                </div>
            </div>
            <!-- // End Title -->
            <div class="row">
                <div v-if="loading"></div>

                <div v-else class="col-lg-4 col-md-6 sm-mb-35px my-2" v-for="item in latest_articles" :key="item.id">
                    <div class="blog-item my-3">
                        <div class="img">
                            <a href="#"><img v-if="item.main_image" class="coverImage" :src="item.main_image.preview" alt=""></a>
                            <a href="#" class="date ar">
								<span class="day">{{getDay(item.created_at)}}</span>
								<span class="month">{{getMonth(item.created_at)}}</span>
							</a>
                        </div>
                        
                        <router-link  :to="{ name: 'singleBlog', params: { id: `${item.id}` } }" class="title titleSize text-right">{{item.title}}</router-link>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!--============= //Artical =============-->

</template>
<script setup>
import {onMounted , ref } from 'vue'
    // store
    import { useHome } from "../store"
    import { storeToRefs } from "pinia"

    const store = useHome()
    const { latest_articles , loading , contactInfoConst } = storeToRefs(store)
    
  const ArticalText = ref('')
    onMounted( async() => {
    
    await store.fetchContactInfo() 
    ArticalText.value =  contactInfoConst.value.filter(item => item.slug === "blogs")[0].value
   })

    // date
    
    const getMonth = created_at =>{
        const month ={ '01' : 'Jan' , '02' : 'Feb' , '03' : 'Mar' , '04' : 'Apr', '05' : 'May', '06' : 'Jun', '07' : 'Jul', '08' : 'Aug', '09' : 'Sept', '10' : 'Oct', '11' : 'Nov', '12' : 'Dec'}

        let indexMonth = created_at.substr(5 , 2)
        return month[indexMonth]
    }
    const getDay = created_at =>{
        let indexDay = created_at.substr(8 , 2)
        return indexDay
    }
    const lang = localStorage.getItem('lang') 
</script>
  <style>
  .coverImage {
    width: 350px;
    height: 192px;
    object-fit: cover;
}
.titleSize {
    height: 108px;
    width: 344px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog-item .ar {
    margin-left: auto !important;
    margin-right: 30px  !important;
    
}

</style>