<template>
    <div class="service-section-1 container" :class="lang==='en'  ? 'text-left' : 'text-right'">

        <div class="row">
            <div class="col-md-12">
                <div class="service layout-2">
                    <div class="clearfix">
                        <div  :class="lang==='en' ? 'icon' : 'icon-ar'"><img src="../../../assets/icons/service-light-1.png" alt=""></div>
                        <a href="#" class="title"> <span v-if="lang==='en'">Track Your Shipment</span>  <span v-else-if="lang==='kr'">شوێنپێی بارەکەت بگرە</span> <span v-else>  تتبع شحنتك</span> </a>
                    </div>
                    <div class="dis clearfix">
                        <div class="col-lg-12 padding-tb-15px">
                            <div class="form-group col-md-9 " :class="lang==='en'  ? 'float-left' : 'float-right'">
                                <input v-if="lang==='ar'" v-model="Id" type="text" class="form-control" id="inputName44" placeholder="رقم الشحنة ">
                                <input v-else-if="lang==='kr'" v-model="Id" type="text" class="form-control" id="inputName44" placeholder="ژمارەی بارکردن">
                                <input v-else v-model="Id" type="text" class="form-control" id="inputName44" placeholder="Shipment Id">
                                <span v-if="validId"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span>  <span v-else>هذا الحقل مطلوب</span></span>
                                <span v-if="notFound"><span v-if="lang==='en'">this feild is required</span> <span v-else-if="lang==='kr'">ئەم بارکردنە بوونی نییە</span> <span v-else>هذه الشحنة غير موجودة</span></span>
                            </div>
                            <div class="form-group col-md-3  " :class="lang==='en'  ? 'float-left' : 'float-right'">
                                <btn class="btn btn-block btn-secondary md" @click="onClickTrack" >
                                    <span v-if="loading">  <span v-if="lang==='ar'">جاري التحميل </span>  <span v-else-if="lang==='kr'"> بارکردن </span>  <span v-else> Loading  </span> ...  </span>
                                    <span v-else>
                                        <i v-if="lang==='ar' || lang==='kr'"  class="fa fa-arrow-left"></i>  <span v-if="lang==='en'">Click To Track</span> <span v-else-if="lang==='kr'">بۆ شوێنپێهەڵگرتن کلیک بکە</span>  <span v-else>  اضغط للتتبع </span>  <i v-if="lang==='en'"  class="fa fa-arrow-right"></i>
                               
                                    </span>
                                 </btn>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script setup>
 // store
 import { useTrack  } from "@/views/track/store"
  import { storeToRefs } from "pinia"

  const store = useTrack()

  const { loading , shipment } = storeToRefs(store)
import { useRouter } from 'vue-router';
const router = useRouter()
import {ref} from "vue"
const Id = ref(null)
const lang = localStorage.getItem('lang') 
const validId = ref(false)
const notFound = ref(false)
const onClickTrack = async()=>{
    if(Id.value){
        await store.fetchShipment(Id.value)
        console.log("search" , Id.value , shipment)
        if(shipment.value === undefined){
            console.log('undefinedundefined')
            notFound.value = true
        }
        else if(Object.keys(shipment.value).length !== 0){
            router.push({ name: 'traking', params: { id: `${Id.value}` } })
        }
        
    }
    else{
        validId.value = true
    }
    
}

import { watch} from 'vue'
watch(Id , ()=>{
    validId.value = false
    notFound.value = false
})
</script>
<style>
.service.layout-2 .icon-ar {
    float: right;
    margin-left: 25px;
}
.float-right{
    float: right !important;
}
</style>

