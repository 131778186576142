import axios from "axios"
import { defineStore } from 'pinia'

export const useHome = defineStore('Home', {
    state: () => ({ 
      listOfErrors:[],
      contactInfoConst : [],
        loading: false, 
        success :false,
        error : false ,
        latest_articles:[],
        sliders:[],
        services:[],
        offices :[],
        service:{},
        loadingHome:false,
        companyInfo : {
          location:"syrie",
          phone :"+971 123456789",
          about : {
            en:" It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable  ",
            ar:"من المعروف منذ زمن بعيد أن القارئ سيُشتت انتباهه بمحتوى الصفحة عند النظر إلى تخطيطها. الهدف من استخدام لوريم إيبسوم هو أن يكون لديه توزيع طبيعي أكثر أو أقل للحروف، بدلاً من استخدام محتوى هنا، محتوى هنا، مما يجعله يبدو وكأنه قابل للقراءة"
          },
          aboutCout:{
            en:" It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable  ",
            ar:"من المعروف منذ زمن بعيد أن القارئ سيُشتت انتباهه بمحتوى الصفحة عند النظر إلى تخطيطها. الهدف من استخدام لوريم إيبسوم هو أن يكون لديه توزيع طبيعي أكثر أو أقل للحروف، بدلاً من استخدام محتوى هنا، محتوى هنا، مما يجعله يبدو وكأنه قابل للقراءة"
          },
          textService :{
         
            en :' In quis luctus dolor. Sed ac libero arcu. Phasellus vulputate ultrices augue, eget feugiat lectus efficitur in. Nulla non pharetra justo. Nunc viverra consectetur bibendum. ',
            ar :' الهدف من استخدام لوريم إيبسوم هو أن يكون لديه توزيع طبيعي أكثر أو أقل للحروف، بدلاً من استخدام محتوى هنا، محتوى هنا، مما يجعله يبدو وكأنه قابل للقر',
          },
          textArtical :{
         
            en :' In quis luctus dolor. Sed ac libero arcu. Phasellus vulputate ultrices augue, eget feugiat lectus efficitur in. Nulla non pharetra justo. Nunc viverra consectetur bibendum. ',
            ar :' الهدف من استخدام لوريم إيبسوم هو أن يكون لديه توزيع طبيعي أكثر أو أقل للحروف، بدلاً من استخدام محتوى هنا، محتوى هنا، مما يجعله يبدو وكأنه قابل للقر',
          },

          contactInfo :{
            faceBook : 'faceBook', 
            instagram : 'instagram', 
          }
        },
    }),
    actions: {
       // 👉 Fetch all Info
        async fetchInfo() {
          this.loadingHome = true
          console.log("loadingHome befor" , this.loadingHome)
          let headers = {}
          const lang = localStorage.getItem('lang') 
          if (lang==='ar')
          {
            headers =  {
              "X-localization":'ar',
            }
          }
          else if (lang==='kr')
          {
            headers =  {
              "X-localization":'ku',
            }
          }
          else{
            headers =  {
              "X-localization":'en',
            }
          }
        
      
        try {
            console.log('t')
          const rest = await axios.get(
            // `http://192.168.1.101:8000/api/v1/home`,
            `https://backend.amanallc.net/api/v1/home`,
            // `https://mazaya.back.dev.warshatec.com/api/v1/home`,
            {
              headers: headers,
            }
          )
          console.log(rest)

          if (rest.status >= 400 && rest.status <= 500) {
            throw rest.status
          }
          
          this.latest_articles = rest.data.data.latest_articles
          this.sliders = rest.data.data.sliders
          this.services = rest.data.data.services
          
        //   return Promise.resolve(rest.data.data)
        } catch (err) {
            console.log(err)
          
          
        //   return Promise.reject(err)
        } finally {

            this.loadingHome = false
            
          console.log("loadingHome after" , this.loadingHome)


        }
        
      },
       // 👉 Fetch all Office
       async fetchOffice() {

        let headers = {}
          const lang = localStorage.getItem('lang') 
          if (lang==='ar')
          {
            headers =  {
              "X-localization":'ar',
            }
          }
          else if (lang==='kr')
          {
            headers =  {
              "X-localization":'ku',
            }
          }
          else{
            headers =  {
              "X-localization":'en',
            }
          }
        this.loading = true
      
        try {
            console.log('t')
          const rest = await axios.get(
            // `http://192.168.1.101:8000/api/v1/data/offices`,
            `https://backend.amanallc.net/api/v1/data/offices`,
            // `https://mazaya.back.dev.warshatec.com/api/v1/data/offices`,

            {
              headers: headers,
            }

          )
          console.log(rest)
          if (rest.status >= 400 && rest.status <= 500) {
            throw rest.status
          }
          
          this.offices = rest.data.data
          console.log("offices" , this.offices)
        //   return Promise.resolve(rest.data.data)
        } catch (err) {
            console.log(err)
            this.offices =[]
          
          
        //   return Promise.reject(err)
        } finally {
            this.loading = false
            console.log('f')

        }
        
      },
      async storeOffer(form){
        // this.updateLoading = true

        console.log(form)
        this.loading = true
        let headers = {}
          const lang = localStorage.getItem('lang') 
          if (lang==='ar')
          {
            headers =  {
              "X-localization":'ar',
            }
          }
          else if (lang==='kr')
          {
            headers =  {
              "X-localization":'ku',
            }
          }
          else{
            headers =  {
              "X-localization":'en',
            }
          }

      const formBody = new FormData()
      for (const property in form) {
        
        if (Array.isArray(form[property])){
          if(property==='attachments'){
            
            for (var i = 0; i < form.attachments.length; i++) {
              
              formBody.append(`${property}[${i}]`, form[property][i])
              
            }
          }
          else{
            
            formBody.append(`${property}`, form[property][0])
            
          }
          
        }
       
        else {
          
          formBody.append(property, form[property])
        }
        
      }

      try {
        let rest = await axios.post(
          // `http://192.168.1.101:8000/api/v1/offerPrice`,
          `https://backend.amanallc.net/api/v1/offerPrice`,
          // `https://mazaya.back.dev.warshatec.com/api/v1/offerPrice`,
          formBody ,
          {
            headers:headers,
            
        }
         
         
        )
       
        if (rest.status >= 400 && rest.status <= 500) {
          
          throw rest.status
        }
        if(rest.data.code === 422)
        {
          console.log(rest.data.errors)
          this.errors = true
          this.listOfErrors = rest.data.errors
          return Promise.resolve(rest.data.data)
        }
        else{
          this.success = true
          return Promise.resolve(rest.data.data)
        }
        
     
      } catch (err) {
        // this.errors = err.response.data.errors
        
        console.log(err)
          this.errors = true
          setTimeout(() => {
            // router.push({ name: `dashboard-shipment-container` })
            
            this.errors = false
          }, 5000)

        return Promise.reject(err)
      } finally {
        
        this.loading = false
      }
      },
       // 👉 Fetch all service
       async fetchService(id) {
        this.loading = true

        console.log('test')
        let headers = {}
          const lang = localStorage.getItem('lang') 
          if (lang==='ar')
          {
            headers =  {
              "X-localization":'ar',
            }
          }
          else if (lang==='kr')
          {
            headers =  {
              "X-localization":'ku',
            }
          }
          else{
            headers =  {
              "X-localization":'en',
            }
          }
        try {
          const rest = await axios.get(
            // `http://192.168.1.101:8000/api/v1/service/${id}`,
            `https://backend.amanallc.net/api/v1/service/${id}`,
            // `https://mazaya.back.dev.warshatec.com/api/v1/service/${id}`,
            {
              headers:headers,
            }

          )
          
          if (rest.status >= 400 && rest.status <= 500) {
            throw rest.status
          }
          
          this.service = rest.data.data
          console.log("service" , this.service)
        //   return Promise.resolve(rest.data.data)
        } catch (err) {
            this.service ={}
          
          
        //   return Promise.reject(err)
        } finally {
            this.loading = false

        }
        
      },
       // 👉 Fetch all service
       async fetchContactInfo() {
        console.log('fetchContactInfo')
        
        this.loading = true
        let headers = {}
          const lang = localStorage.getItem('lang') 
          if (lang==='ar')
          {
            headers =  {
              "X-localization":'ar',
            }
          }
          else if (lang==='kr')
          {
            headers =  {
              "X-localization":'ku',
            }
          }
          else{
            headers =  {
              "X-localization":'en',
            }
          }
        try {
          const rest = await axios.get(
            // `http://192.168.1.101:8000/api/v1/home-content`,
            `https://backend.amanallc.net/api/v1/home-content`,
            // `https://mazaya.back.dev.warshatec.com/api/v1/home-content`,
            {
              headers: headers
            }

          )
          
          if (rest.status >= 400 && rest.status <= 500) {
            throw rest.status
          }
          this.contactInfoConst = rest.data.data
          console.log("contactInfo" , rest.data.data)
        //   return Promise.resolve(rest.data.data)
        } catch (err) {
          console.log("err" , err)
          this.contactInfoConst = []
          
        //   return Promise.reject(err)
        } finally {
            this.loading = false

        }
        
      },
      
    },
  })
