<template>
    <!--============= Service =============-->
    <div  v-if="lang==='en'" class="section padding-tb-100px section-ba-1  text-center" id="Service">

        <div class="container">
            <!-- Title -->
            <div class="section-title margin-bottom-40px">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div class="icon text-main-color"><i class="fa fa-ship"></i></div>
                        <div class="h2"> <span v-if="lang==='en'"> Our Service </span>    </div>

                        <!-- <div class="des"> {{serviceText}} </div> -->

                    </div>
                </div>
            </div>
            <!-- // End Title -->

            <div class="row">

                <div class="col-lg-3 col-md-6" v-for="item in services" :key="item.id">
                    <div class="service-icon-box">
                        <div class="icon"><img class="coverImgService" :src="item.image" alt=""></div>

                        <router-link  class="title h2" :to="{ name: 'singleServices', params: { id: `${item.id}` } }">{{item.title}}</router-link>
                       
                        <div class="des desService"><span>{{ item.description }}</span></div>
                    </div>
                </div>
            </div>



        </div>
    </div>
    <!--============= //Service =============-->
    <!--============= Service =============-->
    
    <div  v-else class="section padding-tb-100px section-ba-1  text-center" id="Service">
        <div class="container">
            <!-- Title -->
            <div class="section-title margin-bottom-40px">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div class="icon text-main-color"><i class="fa fa-ship"></i></div>
                        <div class="h2 directionLtr" ><span v-if="lang==='kr'"> خزمەت گوزاریەکانمان </span>   <span v-else> خدماتنا</span></div>
                        <!-- <div class="des">
                            
                            {{serviceText}}
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- // End Title -->

            <div class="row">

                <div class="col-lg-3 col-md-6" v-for="item in services" :key="item.id">
                    <div class="service-icon-box">
                        <div class="icon"><img class="coverImgService" :src="item.image" alt=""></div>
                        <router-link  class="title h2" :to="{ name: 'singleServices', params: { id: `${item.id}` } }">{{item.title}}</router-link>
                       
                        <div class="des desService"><span>{{ item.description }}</span></div>

                    </div>
                </div>
            </div>



        </div>
    </div>
    <!--============= //Service =============-->
</template>
<script setup>
import {onMounted , ref } from 'vue'
 // store
 import { useHome } from "../store"
  import { storeToRefs } from "pinia"

  const store = useHome()
  const lang = localStorage.getItem('lang') 
  const serviceText = ref('')
  const { services , contactInfoConst } = storeToRefs(store)
  onMounted( async() => {
    
    await store.fetchContactInfo() 
    serviceText.value =  contactInfoConst.value.filter(item => item.slug === "services")[0].value
   })

</script>
<style>
.dec{
    overflow: hidden;
    margin: 0;
    inline-size: 316px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.coverImgService{
    width: 229px;
    height: 140px;
    object-fit: cover;
    margin-bottom: 10px;
}
.desService{
    height: 97px;
    width: 242px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.directionLtr{
    direction: ltr;
}
</style>