<template>
 
  <div  >
    <div v-if="loadingHome" class="loader" >
      <div class="icon">
        <img src="./assets/logo.png"  alt="">
        <div class="spinner mt-4">
        </div>
        
      </div>

    </div>
    <!-- <div v-else> -->
      <HeaderComponents v-if="!loadingHome"  ></HeaderComponents>
    
      <router-view/>
      <FooterComponent v-if="!loadingHome"></FooterComponent>
    <!-- </div> -->
   
  </div>
  
</template>
<script setup>
import {onMounted , ref} from 'vue'
import FooterComponent from "../src/components/FooterComponent.vue"
import HeaderComponents from "../src/components/HeaderComponents.vue"
  // store
  import { useHome } from "@/views/home/store"

  const store = useHome()
  
  import { storeToRefs } from "pinia"
  const { loadingHome } = storeToRefs(store)
  const lang = ref('')
  onMounted(()=>{
    
    console.log('monted app')
    lang.value = localStorage.getItem('lang') || 'en'
    console.log( lang.value ,  lang.value)
    localStorage.setItem('lang' , lang.value)
 
})
</script>


<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  

  font-family: Cairo, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.footer.layout-dark .nile-widget.widget_nav_menu ul li a ,
.form-control,
.titleSize ,
title,
.des,
.about-text,
.page-title h1,
.contact-modal h3,
.title  span,
.sidebar-title-ar h2,
.nile-widget.contact-widget h2.title,
.team.layout-1 h3,
span,
.header ul.link-padding-tb-25px > li > a  ,
.title-text,
.office,
.info-path ::-moz-list-number ,
footer.layout-dark .nile-widget h2.title,
.section-title .h2 ,
.service-icon-box a.title.h2,
.router-link-active, 
.services-list,
.text-right {
  font-family: 'Cairo', sans-serif !important;
  
  
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
header ul.nav-menu{
    width: 581px !important;
}
.loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: gray;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
    .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
