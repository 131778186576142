import axios from "axios"
import { defineStore } from 'pinia'

export const useTrack = defineStore('Track', {
    state: () => ({ 
        loading: false, 
        shipment:{},
    }),
    actions: {
       // 👉 Fetch all Shipment
        async fetchShipment(id) {
          let headers = {}
          const lang = localStorage.getItem('lang') 
          if (lang==='ar')
          {
            headers =  {
              "X-localization":'ar',
            }
          }
          else if (lang==='kr')
          {
            headers =  {
              "X-localization":'ku',
            }
          }
          else{
            headers =  {
              "X-localization":'en',
            }
          }
          console.log(id)
        this.loading = true
      
        try {
          
            console.log('t')
          const rest = await axios.get(
            // `http://192.168.1.101:8000/api/v1/shipment/${id}`,
            `https://backend.amanallc.net/api/v1/shipment/${id}`,
            // `https://mazaya.back.dev.warshatec.com/api/v1/shipment/${id}`,
            {
              headers:headers,
            }
          )
          console.log(rest)
          if (rest.status >= 400 && rest.status <= 500) {
            throw rest.status
          }
          
          this.shipment = rest.data.data
          console.log("shipment",rest.data.data)
          
        //   return Promise.resolve(rest.data.data)
        } catch (err) {
            console.log(err)
          
          
        //   return Promise.reject(err)
        } finally {
            this.loading = false
            console.log('f')

        }
        
      },
      
    },
  })
